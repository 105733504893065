
.container{position:relative;width:1200px;margin:0 auto;padding:0;}
.container .column,
.container .columns{float:left;display:inline;margin-left:10px;margin-right:10px;}
.row{margin-bottom:20px;}
.column.alpha, .columns.alpha{margin-left:0;}
.column.omega, .columns.omega{margin-right:0;}
.container .one.column,
.container .one.columns{width:55px;}
.container .two.columns{width:130px;}
.container .three.columns{width:205px;}
.container .four.columns{width:280px;}
.container .five.columns{width:355px;}
.container .six.columns{width:430px;}
.container .seven.columns{width:505px;}
.container .eight.columns{width:580px;}
.container .nine.columns{width:655px;}
.container .ten.columns{width:730px;}
.container .eleven.columns{width:805px;}
.container .twelve.columns{width:880px;}
.container .thirteen.columns{width:955px;}
.container .fourteen.columns{width:1030px;}
.container .fifteen.columns{width:1105px;}
.container .sixteen.columns{width:1180px;}
.container .one-third.column{width:380px;}
.container .two-thirds.column{width:780px;}
.container .twelve.alt.columns{width:860px;margin-right:30px;}
.container .twelve.alt2.columns{width:860px;margin-left:30px;}
.container .eleven.alt.columns{width:775px;margin-right:30px;}
.container .five.alt.columns{width:320px;margin-right:15px;}
body.boxed,
body.boxed #header{width:1280px;}
@media only screen and (min-width:1200px) and (max-width:1300px){body.boxed,
body.boxed #header{width:100%;}
}
@media only screen and (min-width:960px) and (max-width:1199px){.container{position:relative;width:960px;margin:0 auto;padding:0;}
.container .column,
.container .columns{float:left;display:inline;margin-left:10px;margin-right:10px;}
.row{margin-bottom:20px;}
.column.alpha, .columns.alpha{margin-left:0;}
.column.omega, .columns.omega{margin-right:0;}
.container .one.column,
.container .one.columns{width:40px;}
.container .two.columns{width:100px;}
.container .three.columns{width:160px;}
.container .four.columns{width:220px;}
.container .five.columns{width:280px;}
.container .six.columns{width:340px;}
.container .seven.columns{width:400px;}
.container .eight.columns{width:460px;}
.container .nine.columns{width:520px;}
.container .ten.columns{width:580px;}
.container .eleven.columns{width:640px;}
.container .twelve.columns{width:700px;}
.container .thirteen.columns{width:760px;}
.container .fourteen.columns{width:820px;}
.container .fifteen.columns{width:880px;}
.container .sixteen.columns{width:940px;}
.container .one-third.column{width:300px;}
.container .two-thirds.column{width:620px;}
.container .twelve.alt.columns{width:680px;margin-right:30px;}
.container .twelve.alt2.columns{width:680px;margin-left:30px;}
.container .eleven.alt.columns{width:610px;margin-right:30px;}
.container .five.alt.columns{width:245px;margin-right:15px;}
body.boxed,
body.boxed #header{width:1020px;}
}
@media only screen and (min-width:769px) and (max-width:1040px){body.boxed,
body.boxed #header{width:100%;}
}
@media only screen and (min-width:768px) and (max-width:959px){.container{width:768px;}
.container .column,
.container .columns{margin-left:10px;margin-right:10px;}
.column.alpha, .columns.alpha{margin-left:0;margin-right:10px;}
.column.omega, .columns.omega{margin-right:0;margin-left:10px;}
.alpha.omega{margin-left:0;margin-right:0;}
.container .one.column,
.container .one.columns{width:28px;}
.container .two.columns{width:76px;}
.container .three.columns{width:124px;}
.container .four.columns{width:172px;}
.container .five.columns{width:220px;}
.container .six.columns{width:268px;}
.container .seven.columns{width:316px;}
.container .eight.columns{width:364px;}
.container .nine.columns{width:412px;}
.container .ten.columns{width:460px;}
.container .eleven.columns{width:508px;}
.container .twelve.columns{width:556px;}
.container .thirteen.columns{width:604px;}
.container .fourteen.columns{width:652px;}
.container .fifteen.columns{width:700px;}
.container .sixteen.columns{width:748px;}
.container .one-third.column{width:236px;}
.container .two-thirds.column{width:492px;}
.container .twelve.alt.columns{width:546px;margin-right:20px;}
.container .twelve.alt2.columns{width:546px;margin-left:20px;}
.container .eleven.alt.columns{width:488px;margin-right:20px;}
.container .five.alt.columns{width:185px;margin-right:15px;}
body.boxed,
body.boxed #header{width:100%}
}
@media only screen and (max-width:767px){.container{width:auto;margin-left:10px;margin-right:10px;}
.container .columns,
.container .column{margin:0;}
.container .one.column,
.container .one.columns,
.container .two.columns,
.container .three.columns,
.container .four.columns,
.container .five.columns,
.container .six.columns,
.container .seven.columns,
.container .eight.columns,
.container .nine.columns,
.container .ten.columns,
.container .eleven.columns,
.container .twelve.columns,
.container .thirteen.columns,
.container .fourteen.columns,
.container .fifteen.columns,
.container .sixteen.columns,
.container .one-third.column,
.container .two-thirds.column,
.container .twelve.alt.columns,
.container .twelve.alt2.columns,
.container .five.alt.columns,
.container .eleven.alt.columns{width:100%;}
.container .twelve.alt2.columns{margin-left:0;}
.container .fourteen.carousel.columns{width:240px;}
.container .one.carousel.column{width:30px;}
body.boxed,
body.boxed #header{width:100%}
}
@media only screen and (min-width:480px) and (max-width:767px){.container{margin-top:0px;margin-right:10px;margin-left:10px;margin-bottom:0px;width:auto;}
.container .columns,
.container .column{margin:0;}
.container .one.column,
.container .one.columns,
.container .two.columns,
.container .three.columns,
.container .four.columns,
.container .five.columns,
.container .six.columns,
.container .seven.columns,
.container .eight.columns,
.container .nine.columns,
.container .ten.columns,
.container .eleven.columns,
.container .twelve.columns,
.container .thirteen.columns,
.container .fourteen.columns,
.container .fifteen.columns,
.container .sixteen.columns,
.container .one-third.column,
.container .two-thirds.column,
.container .twelve.alt.columns,
.container .twelve.alt2.columns,
.container .five.alt.columns,
.container .eleven.alt.columns{margin-top:0px;margin-left:0px;margin-bottom:0px;width:100%;}
.container .twelve.alt2.columns{margin-left:0;}
.container .fourteen.carousel.columns{width:340px;}
.container .one.carousel.column{width:40px;}
body.boxed,
body.boxed #header{width:100%}
}
.container:after{content:"\0020";display:block;height:0;clear:both;visibility:hidden;}
.clearfix:before,
.clearfix:after,
.row:before,
.row:after{content:'\0020';display:block;overflow:hidden;visibility:hidden;width:0;height:0;}
.row:after,
.clearfix:after{clear:both;}
.row,
.clearfix{zoom:1;}
.clear{clear:both;display:block;overflow:hidden;visibility:hidden;width:0;height:0;}

