html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-left: 10px;
}

body {
    background: #fff;
    font-size: 14px;
    line-height: 24px;
    color: #888;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

#content-wrapper {
    padding: 0 0 40px 0;
    background-color: #fff;
}

body.boxed {
    margin: 0 auto;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    background: url(../images/bg/brushed_alu.png) repeat;
}

#header {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

body, h1, h2, h3, h4, h5, h6, .button, input[type="button"], input[type="submit"], input[type="text"], input[type="password"], input[type="email"], textarea, select, button {
    font-family: "Segoe UI", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#wrapper img {
    height: auto;
    max-width: 100%;
}

.columns.google-map img {
    max-width: none;
}

img, object, video {
    height: auto;
    display: block;
}

img {
    width: auto;
    border: 0;
    -ms-interpolation-mode: bicubic;
    float: right;
    margin: 0px 0px 0px 0px;
}

.embed {
    position: relative;
    padding: 0px;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.embed iframe, .embed object, .embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-left {
    float: left;
    margin: 5px 0 8px 15px;
}

.image-right {
    float: right;
    margin: 5px 0 8px 15px;
}

.image-right, .image-left {
    padding: 5px;
    border: 1px solid #e0e0e0;
    max-width: 100%;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
}

h1, h2, h3, h4, h5, h6 {
    color: #000000;
    font-weight: 500;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    font-weight: inherit;
}

/* h1{font-size:28px;line-height:50px;}*/

h1 {
    font-size: 20px;
    line-height: 34px;
}

h2 {
    font-size: 24px;
    line-height: 30px;
}

h3 {
    font-size: 20px;
    line-height: 34px;
}

h4 {
    font-size: 18px;
    line-height: 30px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

h6 {
    font-size: 10px;
    line-height: 21px;
}

p {
    margin: 0 0 15px 0;
    line-height: 24px;
}

p img {
    margin: 0;
}

p.lead {
    font-size: 21px;
    line-height: 27px;
    color: #000000;
}

em {
    font-style: italic;
}

strong {
    font-weight: 600;
    color: #000;
}

small {
    font-size: 80%;
}

hr {
    border: solid #ddd;
    border-width: 1px 0 0;
    clear: both;
    margin: 10px 0 30px;
    height: 0;
}

a, a:visited {
    color: #000033;
    text-decoration: none;
    outline: 0;
    -webkit-transition: color 0.1s ease-in-out;
    -moz-transition: color 0.1s ease-in-out;
    -o-transition: color 0.1s ease-in-out;
    -transition: color 0.1s ease-in-out;
}

a:hover, a:focus {}

a b, span b {
    font-family: Verdana, Tahoma;
    font-weight: normal;
    opacity: 0.5;
}

span b {
    line-height: 14px;
}

p a, p a:visited {
    line-height: inherit;
}

ul, ol {
    margin: 0;
}

ul {
    list-style: none outside;
}

ol {
    list-style: decimal;
}

ol, ul.square, ul.circle, ul.disc {
    margin-left: 30px;
}

ul.square {
    list-style: square outside;
}

ul.circle {
    list-style: circle outside;
}

ul.disc {
    list-style: disc outside;
}

ul ul, ul ol, ol ol, ol ul {}

ul ul li, ul ol li, ol ol li, ol ul li {}

li {
    line-height: 18px;
}

ul.large li {
    line-height: 21px;
}

li p {
    line-height: 21px;
}

input[type="text"], input[type="password"], input[type="email"], textarea, select {
    border: 1px solid #e0e0e0;
    padding: 8px 9px;
    outline: none;
    font-size: 14px;
    color: #888;
    margin: 0;
    max-width: 100%;
    display: block;
    background: #fff;
    -webkit-box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
    box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
}

select {
    padding: 0;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, textarea:focus {
    border: 1px solid #d6d6d6;
    color: #888;
}

textarea {
    min-height: 60px;
}

label, legend {
    display: block;
    font-weight: bold;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 5px;
}

select {}

input[type="checkbox"] {
    display: inline;
}

label span, legend span {
    font-weight: normal;
    font-size: 14px;
    color: #444;
}