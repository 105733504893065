@font-face {
    font-family: 'FontAwesome';
    src: url('../font/fontawesome-webfont.eot_v=3.0.1');
    src: url('../font/fontawesome-webfont.eot_') format('embedded-opentype'), url('../font/fontawesome-webfont.woff_v=3.0.1') format('woff'), url('../font/fontawesome-webfont.ttf_v=3.0.1') format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    display: inline;
    width: auto;
    height: auto;
    line-height: normal;
    vertical-align: baseline;
    background-image: none;
    background-position: 0% 0%;
    background-repeat: repeat;
    margin-top: 0;
}

.icon-white,
.nav-pills>.active>a>[class^="icon-"],
.nav-pills>.active>a>[class*=" icon-"],
.nav-list>.active>a>[class^="icon-"],
.nav-list>.active>a>[class*=" icon-"],
.navbar-inverse .nav>.active>a>[class^="icon-"],
.navbar-inverse .nav>.active>a>[class*=" icon-"],
.dropdown-menu>li>a:hover>[class^="icon-"],
.dropdown-menu>li>a:hover>[class*=" icon-"],
.dropdown-menu>.active>a>[class^="icon-"],
.dropdown-menu>.active>a>[class*=" icon-"],
.dropdown-submenu:hover>a>[class^="icon-"],
.dropdown-submenu:hover>a>[class*=" icon-"] {
    background-image: none;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    text-decoration: inherit;
    display: inline-block;
    speak: none;
}

a [class^="icon-"],
a [class*=" icon-"] {
    display: inline-block;
}

.icon-large:before {
    vertical-align: -10%;
    font-size: 1.3333333333333333em;
}

.btn [class^="icon-"],
.nav [class^="icon-"],
.btn [class*=" icon-"],
.nav [class*=" icon-"] {
    display: inline;
}

.btn [class^="icon-"].icon-large,
.nav [class^="icon-"].icon-large,
.btn [class*=" icon-"].icon-large,
.nav [class*=" icon-"].icon-large {
    line-height: .9em;
}

.btn [class^="icon-"].icon-spin,
.nav [class^="icon-"].icon-spin,
.btn [class*=" icon-"].icon-spin,
.nav [class*=" icon-"].icon-spin {
    display: inline-block;
}

.nav-tabs [class^="icon-"],
.nav-pills [class^="icon-"],
.nav-tabs [class*=" icon-"],
.nav-pills [class*=" icon-"] {}

.nav-tabs [class^="icon-"],
.nav-pills [class^="icon-"],
.nav-tabs [class*=" icon-"],
.nav-pills [class*=" icon-"],
.nav-tabs [class^="icon-"].icon-large,
.nav-pills [class^="icon-"].icon-large,
.nav-tabs [class*=" icon-"].icon-large,
.nav-pills [class*=" icon-"].icon-large {
    line-height: .9em;
}

li [class^="icon-"],
.nav li [class^="icon-"],
li [class*=" icon-"],
.nav li [class*=" icon-"] {
    display: inline-block;
    width: 1.25em;
    text-align: center;
}

li [class^="icon-"].icon-large,
.nav li [class^="icon-"].icon-large,
li [class*=" icon-"].icon-large,
.nav li [class*=" icon-"].icon-large {
    width: 1.5625em;
}

ul.icons {
    list-style-type: none;
    text-indent: -0.75em;
}

ul.icons li [class^="icon-"],
ul.icons li [class*=" icon-"] {
    width: .75em;
}

.icon-muted {
    color: #eeeeee;
}

.icon-border {
    border: solid 1px #eeeeee;
    padding: .2em .25em .15em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.icon-2x {
    font-size: 2em;
}

.icon-2x.icon-border {
    border-width: 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.icon-3x {
    font-size: 3em;
}

.icon-3x.icon-border {
    border-width: 3px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.icon-4x {
    font-size: 4em;
}

.icon-4x.icon-border {
    border-width: 4px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

[class^="icon-"].pull-left,
[class*=" icon-"].pull-left {
    margin-right: .3em;
}

[class^="icon-"].pull-right,
[class*=" icon-"].pull-right {
    margin-left: .3em;
}

.btn [class^="icon-"].pull-left.icon-2x,
.btn [class*=" icon-"].pull-left.icon-2x,
.btn [class^="icon-"].pull-right.icon-2x,
.btn [class*=" icon-"].pull-right.icon-2x {
    margin-top: .18em;
}

.btn [class^="icon-"].icon-spin.icon-large,
.btn [class*=" icon-"].icon-spin.icon-large {
    line-height: .8em;
}

.btn.btn-small [class^="icon-"].pull-left.icon-2x,
.btn.btn-small [class*=" icon-"].pull-left.icon-2x,
.btn.btn-small [class^="icon-"].pull-right.icon-2x,
.btn.btn-small [class*=" icon-"].pull-right.icon-2x {
    margin-top: .25em;
}

.btn.btn-large [class^="icon-"],
.btn.btn-large [class*=" icon-"] {
    margin-top: 0;
}

.btn.btn-large [class^="icon-"].pull-left.icon-2x,
.btn.btn-large [class*=" icon-"].pull-left.icon-2x,
.btn.btn-large [class^="icon-"].pull-right.icon-2x,
.btn.btn-large [class*=" icon-"].pull-right.icon-2x {
    margin-top: .05em;
}

.btn.btn-large [class^="icon-"].pull-left.icon-2x,
.btn.btn-large [class*=" icon-"].pull-left.icon-2x {
    margin-right: .2em;
}

.btn.btn-large [class^="icon-"].pull-right.icon-2x,
.btn.btn-large [class*=" icon-"].pull-right.icon-2x {
    margin-left: .2em;
}

.icon-spin {
    display: inline-block;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(359deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
    }
}

@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(359deg);
    }
}

@-ms-keyframes spin {
    0% {
        -ms-transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

@-moz-document url-prefix() {
    .icon-spin {
        height: .9em;
    }
    .btn .icon-spin {
        height: auto;
    }
    .icon-spin.icon-large {
        height: 1.25em;
    }
    .btn .icon-spin.icon-large {
        height: .75em;
    }
}

.icon-glass:before {
    content: "\f000";
}

.icon-music:before {
    content: "\f001";
}

.icon-search:before {
    content: "\f002";
}

.icon-envelope:before {
    content: "\f003";
}

.icon-heart:before {
    content: "\f004";
}

.icon-star:before {
    content: "\f005";
}

.icon-star-empty:before {
    content: "\f006";
}

.icon-user:before {
    content: "\f007";
}

.icon-film:before {
    content: "\f008";
}

.icon-th-large:before {
    content: "\f009";
}

.icon-th:before {
    content: "\f00a";
}

.icon-th-list:before {
    content: "\f00b";
}

.icon-ok:before {
    content: "\f00c";
}

.icon-remove:before {
    content: "\f00d";
}

.icon-zoom-in:before {
    content: "\f00e";
}

.icon-zoom-out:before {
    content: "\f010";
}

.icon-off:before {
    content: "\f011";
}

.icon-signal:before {
    content: "\f012";
}

.icon-cog:before {
    content: "\f013";
}

.icon-trash:before {
    content: "\f014";
}

.icon-home:before {
    content: "\f015";
}

.icon-file:before {
    content: "\f016";
}

.icon-time:before {
    content: "\f017";
}

.icon-road:before {
    content: "\f018";
}

.icon-download-alt:before {
    content: "\f019";
}

.icon-download:before {
    content: "\f01a";
}

.icon-upload:before {
    content: "\f01b";
}

.icon-inbox:before {
    content: "\f01c";
}

.icon-play-circle:before {
    content: "\f01d";
}

.icon-repeat:before,
.icon-rotate-right:before {
    content: "\f01e";
}

.icon-refresh:before {
    content: "\f021";
}

.icon-list-alt:before {
    content: "\f022";
}

.icon-lock:before {
    content: "\f023";
}

.icon-flag:before {
    content: "\f024";
}

.icon-headphones:before {
    content: "\f025";
}

.icon-volume-off:before {
    content: "\f026";
}

.icon-volume-down:before {
    content: "\f027";
}

.icon-volume-up:before {
    content: "\f028";
}

.icon-qrcode:before {
    content: "\f029";
}

.icon-barcode:before {
    content: "\f02a";
}

.icon-tag:before {
    content: "\f02b";
}

.icon-tags:before {
    content: "\f02c";
}

.icon-book:before {
    content: "\f02d";
}

.icon-bookmark:before {
    content: "\f02e";
}

.icon-print:before {
    content: "\f02f";
}

.icon-camera:before {
    content: "\f030";
}

.icon-font:before {
    content: "\f031";
}

.icon-bold:before {
    content: "\f032";
}

.icon-italic:before {
    content: "\f033";
}

.icon-text-height:before {
    content: "\f034";
}

.icon-text-width:before {
    content: "\f035";
}

.icon-align-left:before {
    content: "\f036";
}

.icon-align-center:before {
    content: "\f037";
}

.icon-align-right:before {
    content: "\f038";
}

.icon-align-justify:before {
    content: "\f039";
}

.icon-list:before {
    content: "\f03a";
}

.icon-indent-left:before {
    content: "\f03b";
}

.icon-indent-right:before {
    content: "\f03c";
}

.icon-facetime-video:before {
    content: "\f03d";
}

.icon-picture:before {
    content: "\f03e";
}

.icon-pencil:before {
    content: "\f040";
}

.icon-map-marker:before {
    content: "\f041";
}

.icon-adjust:before {
    content: "\f042";
}

.icon-tint:before {
    content: "\f043";
}

.icon-edit:before {
    content: "\f044";
}

.icon-share:before {
    content: "\f045";
}

.icon-check:before {
    content: "\f046";
}

.icon-move:before {
    content: "\f047";
}

.icon-step-backward:before {
    content: "\f048";
}

.icon-fast-backward:before {
    content: "\f049";
}

.icon-backward:before {
    content: "\f04a";
}

.icon-play:before {
    content: "\f04b";
}

.icon-pause:before {
    content: "\f04c";
}

.icon-stop:before {
    content: "\f04d";
}

.icon-forward:before {
    content: "\f04e";
}

.icon-fast-forward:before {
    content: "\f050";
}

.icon-step-forward:before {
    content: "\f051";
}

.icon-eject:before {
    content: "\f052";
}

.icon-chevron-left:before {
    content: "\f053";
}

.icon-chevron-right:before {
    content: "\f054";
}

.icon-plus-sign:before {
    content: "\f055";
}

.icon-minus-sign:before {
    content: "\f056";
}

.icon-remove-sign:before {
    content: "\f057";
}

.icon-ok-sign:before {
    content: "\f058";
}

.icon-question-sign:before {
    content: "\f059";
}

.icon-info-sign:before {
    content: "\f05a";
}

.icon-screenshot:before {
    content: "\f05b";
}

.icon-remove-circle:before {
    content: "\f05c";
}

.icon-ok-circle:before {
    content: "\f05d";
}

.icon-ban-circle:before {
    content: "\f05e";
}

.icon-arrow-left:before {
    content: "\f060";
}

.icon-arrow-right:before {
    content: "\f061";
}

.icon-arrow-up:before {
    content: "\f062";
}

.icon-arrow-down:before {
    content: "\f063";
}

.icon-share-alt:before,
.icon-mail-forward:before {
    content: "\f064";
}

.icon-resize-full:before {
    content: "\f065";
}

.icon-resize-small:before {
    content: "\f066";
}

.icon-plus:before {
    content: "\f067";
}

.icon-minus:before {
    content: "\f068";
}

.icon-asterisk:before {
    content: "\f069";
}

.icon-exclamation-sign:before {
    content: "\f06a";
}

.icon-gift:before {
    content: "\f06b";
}

.icon-leaf:before {
    content: "\f06c";
}

.icon-fire:before {
    content: "\f06d";
}

.icon-eye-open:before {
    content: "\f06e";
}

.icon-eye-close:before {
    content: "\f070";
}

.icon-warning-sign:before {
    content: "\f071";
}

.icon-plane:before {
    content: "\f072";
}

.icon-calendar:before {
    content: "\f073";
}

.icon-random:before {
    content: "\f074";
}

.icon-comment:before {
    content: "\f075";
}

.icon-magnet:before {
    content: "\f076";
}

.icon-chevron-up:before {
    content: "\f077";
}

.icon-chevron-down:before {
    content: "\f078";
}

.icon-retweet:before {
    content: "\f079";
}

.icon-shopping-cart:before {
    content: "\f07a";
}

.icon-folder-close:before {
    content: "\f07b";
}

.icon-folder-open:before {
    content: "\f07c";
}

.icon-resize-vertical:before {
    content: "\f07d";
}

.icon-resize-horizontal:before {
    content: "\f07e";
}

.icon-bar-chart:before {
    content: "\f080";
}

.icon-camera-retro:before {
    content: "\f083";
}

.icon-key:before {
    content: "\f084";
}

.icon-cogs:before {
    content: "\f085";
}

.icon-comments:before {
    content: "\f086";
}

.icon-thumbs-up:before {
    content: "\f087";
}

.icon-thumbs-down:before {
    content: "\f088";
}

.icon-star-half:before {
    content: "\f089";
}

.icon-heart-empty:before {
    content: "\f08a";
}

.icon-signout:before {
    content: "\f08b";
}

.icon-pushpin:before {
    content: "\f08d";
}

.icon-external-link:before {
    content: "\f08e";
}

.icon-signin:before {
    content: "\f090";
}

.icon-trophy:before {
    content: "\f091";
}

.icon-github-sign:before {
    content: "\f092";
}

.icon-upload-alt:before {
    content: "\f093";
}

.icon-lemon:before {
    content: "\f094";
}

.icon-phone:before {
    content: "\f095";
}

.icon-check-empty:before {
    content: "\f096";
}

.icon-bookmark-empty:before {
    content: "\f097";
}

.icon-phone-sign:before {
    content: "\f098";
}

.icon-github:before {
    content: "\f09b";
}

.icon-unlock:before {
    content: "\f09c";
}

.icon-credit-card:before {
    content: "\f09d";
}

.icon-rss:before {
    content: "\f09e";
}

.icon-hdd:before {
    content: "\f0a0";
}

.icon-bullhorn:before {
    content: "\f0a1";
}

.icon-bell:before {
    content: "\f0a2";
}

.icon-certificate:before {
    content: "\f0a3";
}

.icon-hand-right:before {
    content: "\f0a4";
}

.icon-hand-left:before {
    content: "\f0a5";
}

.icon-hand-up:before {
    content: "\f0a6";
}

.icon-hand-down:before {
    content: "\f0a7";
}

.icon-circle-arrow-left:before {
    content: "\f0a8";
}

.icon-circle-arrow-right:before {
    content: "\f0a9";
}

.icon-circle-arrow-up:before {
    content: "\f0aa";
}

.icon-circle-arrow-down:before {
    content: "\f0ab";
}

.icon-globe:before {
    content: "\f0ac";
}

.icon-wrench:before {
    content: "\f0ad";
}

.icon-tasks:before {
    content: "\f0ae";
}

.icon-filter:before {
    content: "\f0b0";
}

.icon-briefcase:before {
    content: "\f0b1";
}

.icon-fullscreen:before {
    content: "\f0b2";
}

.icon-group:before {
    content: "\f0c0";
}

.icon-link:before {
    content: "\f0c1";
}

.icon-cloud:before {
    content: "\f0c2";
}

.icon-beaker:before {
    content: "\f0c3";
}

.icon-cut:before {
    content: "\f0c4";
}

.icon-copy:before {
    content: "\f0c5";
}

.icon-paper-clip:before {
    content: "\f0c6";
}

.icon-save:before {
    content: "\f0c7";
}

.icon-sign-blank:before {
    content: "\f0c8";
}

.icon-reorder:before {
    content: "\f0c9";
}

.icon-list-ul:before {
    content: "\f0ca";
}

.icon-list-ol:before {
    content: "\f0cb";
}

.icon-strikethrough:before {
    content: "\f0cc";
}

.icon-underline:before {
    content: "\f0cd";
}

.icon-table:before {
    content: "\f0ce";
}

.icon-magic:before {
    content: "\f0d0";
}

.icon-truck:before {
    content: "\f0d1";
}

.icon-money:before {
    content: "\f0d6";
}

.icon-caret-down:before {
    content: "\f0d7";
}

.icon-caret-up:before {
    content: "\f0d8";
}

.icon-caret-left:before {
    content: "\f0d9";
}

.icon-caret-right:before {
    content: "\f0da";
}

.icon-columns:before {
    content: "\f0db";
}

.icon-sort:before {
    content: "\f0dc";
}

.icon-sort-down:before {
    content: "\f0dd";
}

.icon-sort-up:before {
    content: "\f0de";
}

.icon-envelope-alt:before {
    content: "\f0e0";
}

.icon-undo:before,
.icon-rotate-left:before {
    content: "\f0e2";
}

.icon-legal:before {
    content: "\f0e3";
}

.icon-dashboard:before {
    content: "\f0e4";
}

.icon-comment-alt:before {
    content: "\f0e5";
}

.icon-comments-alt:before {
    content: "\f0e6";
}

.icon-bolt:before {
    content: "\f0e7";
}

.icon-sitemap:before {
    content: "\f0e8";
}

.icon-umbrella:before {
    content: "\f0e9";
}

.icon-paste:before {
    content: "\f0ea";
}

.icon-lightbulb:before {
    content: "\f0eb";
}

.icon-exchange:before {
    content: "\f0ec";
}

.icon-cloud-download:before {
    content: "\f0ed";
}

.icon-cloud-upload:before {
    content: "\f0ee";
}

.icon-user-md:before {
    content: "\f0f0";
}

.icon-stethoscope:before {
    content: "\f0f1";
}

.icon-suitcase:before {
    content: "\f0f2";
}

.icon-bell-alt:before {
    content: "\f0f3";
}

.icon-coffee:before {
    content: "\f0f4";
}

.icon-food:before {
    content: "\f0f5";
}

.icon-file-alt:before {
    content: "\f0f6";
}

.icon-building:before {
    content: "\f0f7";
}

.icon-hospital:before {
    content: "\f0f8";
}

.icon-ambulance:before {
    content: "\f0f9";
}

.icon-medkit:before {
    content: "\f0fa";
}

.icon-fighter-jet:before {
    content: "\f0fb";
}

.icon-beer:before {
    content: "\f0fc";
}

.icon-h-sign:before {
    content: "\f0fd";
}

.icon-plus-sign-alt:before {
    content: "\f0fe";
}

.icon-double-angle-left:before {
    content: "\f100";
}

.icon-double-angle-right:before {
    content: "\f101";
}

.icon-double-angle-up:before {
    content: "\f102";
}

.icon-double-angle-down:before {
    content: "\f103";
}

.icon-angle-left:before {
    content: "\f104";
}

.icon-angle-right:before {
    content: "\f105";
}

.icon-angle-up:before {
    content: "\f106";
}

.icon-angle-down:before {
    content: "\f107";
}

.icon-desktop:before {
    content: "\f108";
}

.icon-laptop:before {
    content: "\f109";
}

.icon-tablet:before {
    content: "\f10a";
}

.icon-mobile-phone:before {
    content: "\f10b";
}

.icon-circle-blank:before {
    content: "\f10c";
}

.icon-quote-left:before {
    content: "\f10d";
}

.icon-quote-right:before {
    content: "\f10e";
}

.icon-spinner:before {
    content: "\f110";
}

.icon-circle:before {
    content: "\f111";
}

.icon-reply:before,
.icon-mail-reply:before {
    content: "\f112";
}

.icon-folder-close-alt:before {
    content: "\f114";
}

.icon-folder-open-alt:before {
    content: "\f115";
}

.icon-expand-alt:before {
    content: "\f116";
}

.icon-collapse-alt:before {
    content: "\f117";
}

.icon-smile:before {
    content: "\f118";
}

.icon-frown:before {
    content: "\f119";
}

.icon-meh:before {
    content: "\f11a";
}

.icon-gamepad:before {
    content: "\f11b";
}

.icon-keyboard:before {
    content: "\f11c";
}

.icon-flag-alt:before {
    content: "\f11d";
}

.icon-flag-checkered:before {
    content: "\f11e";
}

.icon-terminal:before {
    content: "\f120";
}

.icon-code:before {
    content: "\f121";
}

.icon-reply-all:before {
    content: "\f122";
}

.icon-mail-reply-all:before {
    content: "\f122";
}

.icon-star-half-full:before,
.icon-star-half-empty:before {
    content: "\f123";
}

.icon-location-arrow:before {
    content: "\f124";
}

.icon-crop:before {
    content: "\f125";
}

.icon-code-fork:before {
    content: "\f126";
}

.icon-unlink:before {
    content: "\f127";
}

.icon-question:before {
    content: "\f128";
}

.icon-info:before {
    content: "\f129";
}

.icon-exclamation:before {
    content: "\f12a";
}

.icon-superscript:before {
    content: "\f12b";
}

.icon-subscript:before {
    content: "\f12c";
}

.icon-eraser:before {
    content: "\f12d";
}

.icon-puzzle-piece:before {
    content: "\f12e";
}

.icon-microphone:before {
    content: "\f130";
}

.icon-microphone-off:before {
    content: "\f131";
}

.icon-shield:before {
    content: "\f132";
}

.icon-calendar-empty:before {
    content: "\f133";
}

.icon-fire-extinguisher:before {
    content: "\f134";
}

.icon-rocket:before {
    content: "\f135";
}

.icon-maxcdn:before {
    content: "\f136";
}

.icon-chevron-sign-left:before {
    content: "\f137";
}

.icon-chevron-sign-right:before {
    content: "\f138";
}

.icon-chevron-sign-up:before {
    content: "\f139";
}

.icon-chevron-sign-down:before {
    content: "\f13a";
}

.icon-html5:before {
    content: "\f13b";
}

.icon-css3:before {
    content: "\f13c";
}

.icon-anchor:before {
    content: "\f13d";
}

.icon-unlock-alt:before {
    content: "\f13e";
}

.icon-bullseye:before {
    content: "\f140";
}

.icon-ellipsis-horizontal:before {
    content: "\f141";
}

.icon-ellipsis-vertical:before {
    content: "\f142";
}

.icon-rss-sign:before {
    content: "\f143";
}

.icon-play-sign:before {
    content: "\f144";
}

.icon-ticket:before {
    content: "\f145";
}

.icon-minus-sign-alt:before {
    content: "\f146";
}

.icon-check-minus:before {
    content: "\f147";
}

.icon-level-up:before {
    content: "\f148";
}

.icon-level-down:before {
    content: "\f149";
}

.icon-check-sign:before {
    content: "\f14a";
}

.icon-edit-sign:before {
    content: "\f14b";
}

.icon-external-link-sign:before {
    content: "\f14c";
}

.icon-share-sign:before {
    content: "\f14d";
}

@font-face {
    font-family: 'fontello';
    src: url('../font/fontello.eot');
    src: url('../font/fontello.eot') format('embedded-opentype'), url('../font/fontello.woff') format('woff'), url('../font/fontello.ttf') format('truetype'), url('../font/fontello.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.social-icons-footer li i {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    margin: 0 9px 0 3px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 16px;
    font-size: 16px;
}

.social-icons li i {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 16px;
    font-size: 16px;
    width: auto;
}

.social-icons-footer li:last-child i {
    margin-right: 0;
}

.icon-twitter {
    font-size: 14px !important;
}

.social-icons-footer .icon-facebook {
    margin: 0 6px 0 1px !important;
}

.icon-delicious:before {
    content: '\23';
}

.icon-paypal:before {
    content: '\24';
}

.icon-gplus:before {
    content: '\2b';
}

.icon-stumbleupon:before {
    content: '\2f';
}

.icon-fivehundredpx:before {
    content: '\30';
}

.icon-pinterest:before {
    content: '\31';
}

.icon-forrst:before {
    content: '\3a';
}

.icon-digg:before {
    content: '\3b';
}

.icon-spotify:before {
    content: '\3d';
}

.icon-reddit:before {
    content: '\3e';
}

.icon-appstore:before {
    content: '\41';
}

.icon-blogger:before {
    content: '\42';
}

.icon-dribbble:before {
    content: '\44';
}

.icon-evernote:before {
    content: '\45';
}

.icon-flickr:before {
    content: '\46';
}

.icon-linkedin:before {
    content: '\4c';
}

.icon-rss:before {
    content: '\52';
}

.icon-twitter:before {
    content: '\54';
}

.icon-youtube:before {
    content: '\55';
}

.icon-vimeo:before {
    content: '\56';
}

.icon-xing:before {
    content: '\58';
}

.icon-yahoo:before {
    content: '\59';
}

.icon-amazon:before {
    content: '\61';
}

.icon-steam:before {
    content: '\62';
}

.icon-dropbox:before {
    content: '\64';
}

.icon-facebook:before {
    content: '\66';
}

.icon-github:before {
    content: '\67';
}

.icon-lastfm:before {
    content: '\6c';
}

.icon-tumblr:before {
    content: '\74';
}

.icon-wordpress:before {
    content: '\77';
}

.icon-linkedin-rect:before {
    content: '\f31b';
}

.icon-instagram:before {
    content: '\f31f';
}